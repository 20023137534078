import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';

export const addOverflowTile = (
  tilerArea: RenderVideoStore[],
  overflowEndpoints: string[]
): void => {
  if (!overflowEndpoints.length) return;

  const [secondToLastElement, localVideo] = tilerArea.slice(tilerArea.length - 2);
  const secondToLastVisibleElementId = secondToLastElement.stream.id;
  secondToLastElement.stream.id = 'local';
  localVideo.stream.id = 'overflow';
  overflowEndpoints.unshift(secondToLastVisibleElementId);
};
