import { sample } from 'effector';
import { meetingStore, startMeeting } from '@/store/meeting';
import { watchEndpoint, updateEndpoints } from '@/store/webrtc/endpoints';
import { $canvas, $layoutType } from '@/store/layout';
import { handleEndpointUpdate } from '@/store/webrtc/handleEndpointUpdate';

sample({
  clock: startMeeting.doneData,
  fn: (meeting) => {
    meeting.endpointsMap.watch(() => watchEndpoint());
  },
  target: watchEndpoint,
});

sample({
  clock: watchEndpoint,
  source: [meetingStore, $canvas, $layoutType],
  fn: ([{ meeting }, canvas, layoutType]) => {
    if (!meeting) return;

    return handleEndpointUpdate(meeting, canvas, layoutType);
  },
  target: updateEndpoints,
});
