import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
import { getLayout } from '@/store/layout';
import { VoxTilerInput, VoxTilerOptions } from '@voximplant/tiler';
import { activeTiler, initTiler } from '@/helpers/initTiler';
import { priorityByPin } from '@/store/webrtc/endpoints';
import { CanvasDef } from '@/helpers/layouts';

export const composeEndpoints = (
  canvas: CanvasDef,
  endpoints: string[]
): RenderVideoStore[] | null => {
  endpoints.push('local');

  if (canvas.width <= 0 || canvas.height <= 0) {
    return null;
  }
  const convertEndpoints: VoxTilerInput[] = [];
  endpoints.forEach((endpoint) => {
    const id = endpoint;
    const area = priorityByPin.includes(endpoint) ? 0 : 1;
    convertEndpoints.push(({
      id,
      area,
    } as unknown) as VoxTilerInput);
  });

  const layout = getLayout();
  const areas = layout.createTilerDrawAreas();
  const voxTilerOptions: VoxTilerOptions = {
    ...canvas,
    outputFormat: 'web',
    areas,
  };

  initTiler(voxTilerOptions);
  return activeTiler.compose(convertEndpoints) as RenderVideoStore[];
};
