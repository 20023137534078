
  import { defineComponent } from 'vue';
  import { Button, Popup, Typography } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  import { closePopup } from '@/store/popup';
  import router from '@/router';
  import { meetingStore, requestJoinMeeting } from '@/store/meeting';
  import { useStore } from 'effector-vue/composition';

  export default defineComponent({
    name: 'UserJoinAwaitingPopUp',
    components: {
      Popup,
      Button,
      Typography,
    },
    props: {
      isTimeout: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const meeting = useStore(meetingStore);
      const popUpText = t(
        `join.userAwaitingPopUp.${props.isTimeout ? 'timeout_text' : 'reject_text'}`
      );

      const closeAwaitingPopUp = () => {
        closePopup();
        router.replace({
          name: 'Left',
        });
      };
      const retryRequestToApprove = async () => {
        if (meeting.value.meetingId) {
          closePopup();
          await requestJoinMeeting(meeting.value.meetingId);
        }
      };

      return {
        t,
        closeAwaitingPopUp,
        retryRequestToApprove,
        popUpText,
      };
    },
  });
