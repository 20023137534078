import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77a01f8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    class: "user-awaiting-popup",
    title: _ctx.t('join.userAwaitingPopUp.title')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Typography, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.popUpText), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isTimeout)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "button",
              width: "fill-container",
              mode: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.retryRequestToApprove()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("join.userAwaitingPopUp.actionBtn")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          class: "button",
          width: "fill-container",
          mode: "secondary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeAwaitingPopUp()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("join.userAwaitingPopUp.secondBtn")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}