import { createEffect, guard, sample } from 'effector';
import { meetingStore } from '@/store/meeting';
import { endpointEventList } from '@/store/webrtc/endpointManager';
import { $devices, setActiveDevices } from '@/store/devices/index';
import {
  toggleMirrorAudioStream,
  toggleMirrorVideoStream,
  $mirrorStore,
  requestMirrorStream,
} from '@/store/mirrorMedia/index';
import { requestMirrorStreamFx } from '@/store/mirrorMedia/requestMirrorStream';
import { MirrorTracks } from '@/store/mirrorMedia/MirrorTracks';
import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';
import { $hasSeparateSharing, $sharing } from '@/store/sharing/index';
import { MediaDescription } from '@/store/webrtc/endpointTypes';
import { DevicesStore } from '@/store/devices/DevicesStore';

$mirrorStore
  .on(requestMirrorStreamFx.doneData, (_, { mirrorStore, stopped }) => {
    const sharingTracks = $sharing.getState().tracks;
    console.log('$mirrorStore', 'requestMirrorStream.doneData get', {
      mirrorStore,
      stopped,
      sharingTracks,
    });
    const meeting = meetingStore.getState().meeting;
    console.log('$mirrorStore', 'requestMirrorStream.doneData get meeting', meeting);
    if (meeting) {
      // replace audio always because of MacOS && iPhone
      console.log('$mirrorStore', 'requestMirrorStream.doneData replace audio', {
        from: stopped.audioPreview,
        to: mirrorStore.audioPreview,
      });
      meeting.replaceMedia(
        { track: stopped.audioPreview as MediaStreamTrack, kind: 'audio' },
        { track: mirrorStore.audioPreview as MediaStreamTrack, kind: 'audio' }
      );
      endpointEventList.updateLocalEndpoint({
        from: { track: stopped.audioPreview as MediaStreamTrack, kind: 'audio' },
        to: { track: mirrorStore.audioPreview as MediaStreamTrack, kind: 'audio' },
      });
      if (!$devices.getState().audioEnabled && mirrorStore.audioPreview)
        mirrorStore.audioPreview.enabled = false;

      if (stopped.videoPreview && !sharingTracks.length) {
        // replace video if camera was changed
        console.log(
          '$mirrorStore',
          'requestMirrorStream.doneData replace video if camera was changed',
          {
            from: stopped.videoPreview,
            to: mirrorStore.videoPreview,
          }
        );
        meeting.replaceMedia(
          { track: stopped.videoPreview as MediaStreamTrack, kind: 'video' },
          { track: mirrorStore.videoPreview as MediaStreamTrack, kind: 'video' }
        );
        endpointEventList.updateLocalEndpoint({
          from: { track: stopped.videoPreview as MediaStreamTrack, kind: 'video' },
          to: { track: mirrorStore.videoPreview as MediaStreamTrack, kind: 'video' },
        });
      } else if (
        !stopped.videoPreview &&
        mirrorStore.videoPreview &&
        sharingTracks.length &&
        !$hasSeparateSharing.getState()
      ) {
        // add video and move sharing to new peer connection
        console.log(
          '$mirrorStore',
          'requestMirrorStream.doneData add video track when sharing exists',
          mirrorStore.videoPreview,
          sharingTracks
        );
        const localVideoDescription: MediaDescription = {
          kind: 'video',
          track: mirrorStore.videoPreview,
        };
        meeting.addMedia([localVideoDescription]);
      } else if (mirrorStore.videoPreview) {
        // add video
        console.log(
          '$mirrorStore',
          'requestMirrorStream.doneData add video track',
          mirrorStore.videoPreview
        );
        meeting.addMedia([{ track: mirrorStore.videoPreview as MediaStreamTrack, kind: 'video' }]);
        endpointEventList.updateLocalEndpoint({
          from: null,
          to: { track: mirrorStore.videoPreview as MediaStreamTrack, kind: 'video' },
        });
      }
    }
    console.log('$mirrorStore', 'requestMirrorStream.doneData return', mirrorStore);
    return mirrorStore;
  })
  .on(toggleMirrorAudioStream, (store: MirrorTracks) => {
    if (!$devices.getState().audioEnabled) {
      if (store.audioPreview) store.audioPreview.enabled = false;
      console.log('$mirrorStore', 'toggleMirrorAudioStream return', store.audioPreview?.enabled);
    } else {
      if (store.audioPreview) store.audioPreview.enabled = true;
      console.log('$mirrorStore', 'toggleMirrorAudioStream return', store.audioPreview?.enabled);
    }
    return { ...store };
  })
  .on(toggleMirrorVideoStream, (store: MirrorTracks) => {
    if (store.videoPreview) {
      console.log('$mirrorStore', 'toggleMirrorVideoStream start with store', store);
      const meeting = meetingStore.getState().meeting;
      if (meeting) {
        meeting.removeMedia([{ track: store.videoPreview as MediaStreamTrack, kind: 'video' }]);
      }
      console.log('$mirrorStore', 'toggleMirrorVideoStream meeting without media', meeting);
      store.videoPreview?.stop();

      store['videoPreview'] = void 0; // set void 0 instead of deleting param for detect isFirstVideoRequest in requestMirrorStream
      console.log('$mirrorStore', 'toggleMirrorVideoStream off', store.videoPreview);
      return { ...store };
    } else {
      const selectedAudioDevice = $devices.getState().selectedAudioDevice;
      const selectedVideoDevice = $devices.getState().selectedVideoDevice;
      console.log('$mirrorStore', 'toggleMirrorVideoStream on with devices', {
        selectedAudioDevice,
        selectedVideoDevice,
      });
      requestMirrorStream({
        selectedAudioDevice,
        selectedVideoDevice,
      });
    }
  });

const deviceWatchFx = createEffect(([mirrorStore, devices]: [MirrorTracks, DevicesStore]) => {
  const audioSettings = mirrorStore.audioPreview?.getSettings();
  const videoSettings = mirrorStore.videoPreview?.getSettings();
  console.log('mirrorMedia store', 'deviceWatcher start device params', {
    audioDeviceId: audioSettings?.deviceId,
    videoDeviceId: videoSettings?.deviceId,
    audioDevices: devices.audioDevices,
    videoDevices: devices.videoDevices,
  });
  let selectedAudioDevice: AudioDeviceInfo | undefined = void 0;
  let selectedVideoDevice: VideoDeviceInfo | undefined = void 0;
  if (audioSettings && devices.audioDevices) {
    const selected = devices.audioDevices.find((device) => device.value === audioSettings.deviceId);
    if (selected) selectedAudioDevice = selected;
  }
  if (videoSettings && devices.videoDevices) {
    const selected = devices.videoDevices.find((device) => device.value === videoSettings.deviceId);
    if (selected) selectedVideoDevice = selected;
  }
  if (
    devices.selectedAudioDevice !== selectedAudioDevice ||
    devices.selectedVideoDevice !== selectedVideoDevice
  ) {
    console.log('mirrorMedia store', 'deviceWatcher call setActiveDevices with', {
      selectedAudioDevice,
      selectedVideoDevice,
    });
    setActiveDevices({ selectedAudioDevice, selectedVideoDevice });
  }
});

sample({
  clock: $mirrorStore,
  source: [$mirrorStore, $devices],
  target: deviceWatchFx,
});

const $isPossibleToRequestMirrorStream = requestMirrorStreamFx.pending.map((pending) => !pending);

guard({
  clock: requestMirrorStream,
  filter: $isPossibleToRequestMirrorStream,
  target: requestMirrorStreamFx,
});
