
  import Logo from '@/components/Logo.vue';
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import SettingsInDefault from '@/components/layout-settings/SettingsInDefault.vue';
  import { useStore } from 'effector-vue/composition';
  import {
    $isAuthorizedGuestSession,
    $isGuest,
    AuthState,
    authStore,
    restoreAuth,
  } from '@/store/auth';
  import { useRouter } from 'vue-router';
  import { meetingStore, restoreMeeting } from '@/store/meeting';
  import { getDevices, toggleAudioEvent, videoPermanentDisabled } from '@/store/devices';
  import { requestMirrorStreamFx } from '@/store/mirrorMedia/index';
  import '@/store/mirrorMedia/init';
  import '@/store/devices/init';
  import WrongConferenceIDPopUp from '@/components/popups/WrongConferenceIDPopUp.vue';
  import { $popup, openPopup } from '@/store/popup';
  import '@/store/chat/init';
  import '@/store/webrtc/init';
  import { useMatchSupportedBrowser } from '@/hooks/useMatchSupportedBrowser';
  import UserJoinAwaitingPopUp from '@/components/popups/UserJoinAwaitingPopUp.vue';
  import { delay } from '@/helpers/delay';

  export default defineComponent({
    name: 'Join',
    components: {
      WrongConferenceIDPopUp,
      Logo,
      SettingsInDefault,
      UserJoinAwaitingPopUp,
    },
    props: {
      conference: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const authS = useStore(authStore);
      const meeting = useStore(meetingStore);
      const popup = useStore($popup);
      const showAwaitingPopUp = computed(
        () =>
          popup.value.opened &&
          (popup.value.section === 'join_approval_rejected' ||
            popup.value.section === 'join_approval_timeout')
      );
      const router = useRouter();
      console.log('Join views', 'authS.value.authState', AuthState[authS.value.authState]);
      sessionStorage.removeItem('backLink');
      const isWrongConferenceID = ref(false);
      let requestResult = ref<string | void>('');

      async function mirrorStreamCatchHandler(e: Error): Promise<string> {
        let errorType;
        console.error('Join views', 'ERROR Get device', e.message);
        errorType = 'noCamera'; // if camera stream getting error because get AbortError или OverConstrainError
        await getDevices();
        videoPermanentDisabled();

        await requestMirrorStreamFx({}).catch((e) => {
          toggleAudioEvent();
          switch (e.message) {
            case 'NotAllowedError':
              openPopup('device-permission');
              return (errorType = 'browserPermission');
            case 'AbortError':
              return (errorType = 'AbortError');
            default:
              return (errorType = 'otherError');
          }
        });
        return errorType;
      }

      // TODO: use effector for this logic. Here should be only store subscribe
      let initRetries = 0;
      const maxRetries = 5;
      const initMeeting = async () => {
        initRetries++;
        if (initRetries > maxRetries) {
          if (popup.value.section !== 'auth-failed') openPopup('auth-failed');
          return;
        }

        if (authS.value.authState !== AuthState.OAuth) {
          try {
            await restoreAuth();
            await restoreMeeting(props.conference).then(({ isWrongConferenceId }) => {
              if (isWrongConferenceId) {
                isWrongConferenceID.value = true;
              }
            });
            requestMirrorStreamFx({})
              .then(() => {
                console.log('Join views', 'Join call getDevices');
                getDevices();
              })
              .catch(async (e) => {
                console.error('Join views', 'ERROR Join getDevices', e);
                requestResult.value = await mirrorStreamCatchHandler(e);
              });
          } catch (e) {
            sessionStorage.setItem('backLink', props.conference);

            if (e.status === 401) {
              await delay(1000);
              await initMeeting();
              return;
            }

            await router.replace({
              name: 'Signin',
            });
          }
        } else {
          try {
            await restoreMeeting(props.conference);
            if (!meeting.value.voxMeetingId) {
              restoreMeeting(props.conference) // should always be called for update voxMeetingId
                .then(({ isWrongConferenceId }) => {
                  if (isWrongConferenceId) {
                    isWrongConferenceID.value = true;
                  }
                });
            }
            requestMirrorStreamFx({})
              .then(() => {
                console.log('Join views', 'Join call getDevices');
                getDevices();
              })
              .catch(async (e) => {
                console.error('Join views', 'ERROR Join getDevices', e);
                requestResult.value = await mirrorStreamCatchHandler(e);
              });
          } catch (e) {
            await router.replace({
              name: 'Signin',
            });
          }
        }
      };

      onMounted(async () => {
        if (useMatchSupportedBrowser()) {
          await router.replace({
            name: 'Signin',
          });
          return;
        }

        await initMeeting();
      });

      return {
        authS,
        popup,
        showAwaitingPopUp,
        isWrongConferenceID,
        requestResult,
      };
    },
  });
