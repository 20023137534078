
  import { defineComponent } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import { Popup, Typography } from '@voximplant/spaceui';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'WrongConferenceIDPopUp',
    components: {
      Popup,
      Button,
      Typography,
    },
    setup() {
      const { t } = useI18n();
      const router = useRouter();
      const title = t('join.wrongID');

      const handleRedirect = () => {
        router.push({ name: 'Signin' });
      };

      return {
        title,
        handleRedirect,
      };
    },
  });
