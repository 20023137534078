import { fixLocalVideo } from '@/helpers/layout-reordering';
import { addOverflowTile } from '@/helpers/layout-reordering/addOverflowTile';
import { CanvasDef } from '@/helpers/layouts';
import { composeEndpoints } from '@/store/endpointMedia/composeEndpoints';
import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
import { $layoutType } from '@/store/layout';

export const generateTilerArea = (
  canvas: CanvasDef,
  endpointIds: string[]
): RenderVideoStore[] | undefined => {
  const tilerArea = composeEndpoints(canvas, endpointIds);
  // add users that don't fit in the tiler grid
  if (!tilerArea) return;
  const tilerAreaIds = tilerArea.map((tile) => tile.stream.id);
  const overflowUsersIds = endpointIds.filter((endpointId) => {
    return !tilerAreaIds.includes(endpointId);
  });

  // swap last element ID in grid and local
  fixLocalVideo(tilerArea, overflowUsersIds);
  if ($layoutType.getState() !== 'demonstration') {
    addOverflowTile(tilerArea, overflowUsersIds);
  }
  overflowUsersIds.forEach((overflowUserId) => {
    tilerArea?.push({
      stream: {
        area: 2,
        id: overflowUserId,
      },
      top: 0,
      left: 0,
      height: 0,
      width: 0,
    });
  });

  return tilerArea;
};
