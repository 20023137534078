import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
import {
  $devices,
  selectSpeakerDevice,
  setActiveDevices,
  setVideoQuality,
  toggleAudioEvent,
  videoPermanentDisabled,
} from '@/store/devices/index';
import { $mirrorStore } from '@/store/mirrorMedia/index';
import { getDevices } from '@/store/devices/getDevices';
import { meetingStore } from '@/store/meeting';
import { VideoQuality } from '@/store/devices/VideoQuality';
import { subscribeOnDeviceChange } from '@/store/devices/onDeviceChange';

subscribeOnDeviceChange();

$devices
  .on(getDevices.doneData, (store, { audioDevices, speakerDevices, videoDevices }) => {
    let selectedAudioDevice = audioDevices?.length ? audioDevices?.[0] : void 0;
    let selectedVideoDevice = videoDevices?.length ? videoDevices?.[0] : void 0;
    let selectedSpeakerDevices = speakerDevices?.length ? speakerDevices?.[0] : void 0;
    const audioSettings = $mirrorStore.getState().audioPreview?.getSettings();
    const videoSettings = $mirrorStore.getState().videoPreview?.getSettings();
    console.log('$devices', 'getDevices.doneData media device', {
      audioDeviceId: audioSettings?.deviceId,
      videoDeviceId: videoSettings?.deviceId,
    });
    if (audioSettings && audioDevices) {
      const audioSelected = audioDevices.find((device) => device.value === audioSettings.deviceId);
      if (audioSelected) selectedAudioDevice = audioSelected;
      console.log('$devices', 'getDevices.doneData find audioSelected', audioSelected);
    }
    if (audioSettings && speakerDevices) {
      const speakerSelected = speakerDevices.find(
        (device) => device.value === audioSettings.deviceId
      );
      if (speakerSelected) selectedSpeakerDevices = speakerSelected;
      console.log('$devices', 'getDevices.doneData find speakerSelected', speakerSelected);
    }
    if (videoSettings && videoDevices) {
      const videoSelected = videoDevices.find((device) => device.value === videoSettings.deviceId);
      if (videoSelected) selectedVideoDevice = videoSelected;
      console.log('$devices', 'getDevices.doneData find videoSelected', videoSelected);
    }
    const devices = {
      ...store,
      audioDevices,
      selectedSpeakerDevices,
      selectedAudioDevice,
      selectedVideoDevice,
      speakerDevices,
      videoDevices,
      requestDone: true,
      selectedQuality: VideoQuality.hd,
    };
    console.log('$devices', 'getDevices.doneData return', devices);
    return devices;
  })
  .on(setActiveDevices, (store, { selectedAudioDevice, selectedVideoDevice }) => {
    console.log('$devices', 'setActiveDevices get', { selectedAudioDevice, selectedVideoDevice });
    const newState = {
      selectedAudioDevice: selectedAudioDevice ?? store.selectedAudioDevice,
      selectedVideoDevice: selectedVideoDevice ?? store.selectedVideoDevice,
    };
    console.log('$devices', 'setActiveDevices return', newState);
    return { ...store, ...newState };
  })
  .on(setVideoQuality, (store, selectedQuality) => {
    console.log('$devices', 'setVideoQuality return', selectedQuality);
    return { ...store, selectedQuality };
  })
  .on(toggleAudioEvent, (store) => {
    store.audioEnabled = !store.audioEnabled;
    meetingStore.getState().meeting?.muteMicrophone(!store.audioEnabled);
    console.log('$devices', 'toggleAudioEvent return', store.audioEnabled);
    return { ...store };
  })
  .on(videoPermanentDisabled, (store) => {
    store.videoDisabled = true;
    console.log('$devices', 'videoPermanentDisabled return', store.videoDisabled);
    return { ...store };
  })
  .on(selectSpeakerDevice, (store, selectedSpeakerDevice: AudioDeviceInfo | undefined) => {
    store.selectedSpeakerDevices = selectedSpeakerDevice;
    console.log('$devices', 'selectSpeakerDevice return', store.selectedSpeakerDevices);
    return { ...store };
  });
