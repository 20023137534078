import { reorderEndpoints } from '@/helpers/layout-reordering';
import { CanvasDef, LayoutType } from '@/helpers/layouts';
import { updatePinState } from '@/helpers/pin';
import { priorityByPin } from '@/store/webrtc/endpoints';
import { generateTilerArea } from '@/store/webrtc/generateTilerArea';
import { Conference, Endpoint } from '@voximplant/websdk/modules/conference';
import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';

export const handleEndpointUpdate = (
  meeting: Conference,
  canvas: CanvasDef,
  layoutType: LayoutType
): RenderVideoStore[] | undefined => {
  if (!meeting) return;
  const endpointsMapArray: Endpoint[] = Object.values(meeting.endpointsMap.value);

  const endpointIds: string[] = endpointsMapArray.map((element) => element.id);
  updatePinState(endpointIds);

  const hasEndpointSharingEndpoint = Boolean(priorityByPin.length);

  const isGridWithoutSharing = layoutType === 'grid' && !hasEndpointSharingEndpoint;

  const reorderedEndpointIds = isGridWithoutSharing ? endpointIds : reorderEndpoints(endpointIds);

  return generateTilerArea(canvas, reorderedEndpointIds);
};
