import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sign-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsInDefault = _resolveComponent("SettingsInDefault")!
  const _component_WrongConferenceIDPopUp = _resolveComponent("WrongConferenceIDPopUp")!
  const _component_UserJoinAwaitingPopUp = _resolveComponent("UserJoinAwaitingPopUp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authS.authState === 1)
      ? (_openBlock(), _createBlock(_component_SettingsInDefault, {
          key: 0,
          conference: _ctx.conference,
          deviceError: _ctx.requestResult
        }, null, 8, ["conference", "deviceError"]))
      : _createCommentVNode("", true),
    (_ctx.isWrongConferenceID)
      ? (_openBlock(), _createBlock(_component_WrongConferenceIDPopUp, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.showAwaitingPopUp)
      ? (_openBlock(), _createBlock(_component_UserJoinAwaitingPopUp, {
          key: 2,
          isTimeout: _ctx.popup.section === 'join_approval_timeout'
        }, null, 8, ["isTimeout"]))
      : _createCommentVNode("", true)
  ]))
}