import { EffectParams } from 'effector';
import { $mirrorStore, requestMirrorStreamFx } from '@/store/mirrorMedia/index';
import { MirrorTracks } from '@/store/mirrorMedia/MirrorTracks';
import { MediaDescription, TrackTypes } from '@/store/webrtc/endpointTypes';
import { $devices } from '@/store/devices/index';
import { detectAudioWorklet, prepareAudioNodes } from '@/helpers/audioWorklet';
import { detectVendor, Vendor } from '@/helpers/vendor';

// requestMirrorStream trigger deviceWatcher which call setActiveDevices
requestMirrorStreamFx.use(
  async (
    ev
  ): Promise<{
    mirrorStore: MirrorTracks;
    stopped: MirrorTracks;
    sharingTracks: MediaDescription[];
  }> => {
    console.log('mirrorMedia store', 'requestMirrorStream.use get', {
      selectedAudioDevice: ev.selectedAudioDevice?.value,
      selectedVideoDevice: ev.selectedVideoDevice?.value,
    });
    const prevAudioPreview = $mirrorStore.getState().audioPreview;
    const prevVideoPreview = $mirrorStore.getState().videoPreview;
    const constraints: MediaStreamConstraints = getConstraints(ev);
    let media;
    try {
      media = await navigator.mediaDevices.getUserMedia(constraints);
      console.log('mirrorMedia store', 'requestMirrorStream.use get media', media);
    } catch (e) {
      if (e.name === 'OverconstrainedError') {
        // processing of weak cameras that do not support the requested params
        constraints.video = {
          deviceId: ev.selectedVideoDevice?.value,
        }; // reset the params of the requested video
        try {
          media = await navigator.mediaDevices.getUserMedia(constraints); // trying to get a MediaStream again
          console.log('mirrorMedia store', 'requestMirrorStream media without video params', media);
        } catch (e) {
          throw Error(e.name);
        }
      } else {
        throw Error(e.name);
      }
    }
    const audioTracks = media.getAudioTracks();
    const videoTracks = media.getVideoTracks();
    let audioContext: AudioContext | undefined = void 0;
    console.log('mirrorMedia store', 'requestMirrorStream.use get All tracks', {
      audioTracks,
      videoTracks,
    });
    if (detectAudioWorklet()) {
      audioContext = await prepareAudioNodes(audioTracks[0]);
    }
    if (audioTracks.length) {
      const audioPreview = audioTracks[0];
      const videoPreview = videoTracks[0];
      prevAudioPreview?.stop();
      prevVideoPreview?.stop();
      const stopped = { audioPreview: prevAudioPreview, videoPreview: prevVideoPreview };
      const sharingTracks: { track: MediaStreamTrack; kind: TrackTypes }[] = ev.sharingTracks || [];
      const tracks = {
        mirrorStore: { audioPreview, videoPreview, audioContext },
        stopped,
        sharingTracks,
      };
      console.log('mirrorMedia store', 'requestMirrorStream.use', tracks);
      return tracks;
    } else {
      console.error('mirrorMedia store', 'requestMirrorStream.use', 'ERROR No mic devices');
      throw 'No mic devices';
    }
  }
);

const getConstraints = (ev: EffectParams<typeof requestMirrorStreamFx>): MediaStreamConstraints => {
  const constraints: MediaStreamConstraints = {};
  //------------------------------Audio constraints ------------------------------------------
  const audioConstraints = [];
  if (detectVendor() === Vendor.chrome) {
    audioConstraints.push(
      { googEchoCancellation: true },
      { googHighpassFilter: false },
      { googAudioMirroring: false },
      { googEchoCancellation2: false },
      { googAutoGainControl: true },
      { googNoiseSuppression: true }
    );
  } else {
    audioConstraints.push(
      { autoGainControl: true },
      { noiseSuppression: true },
      { echoCancellation: true }
    );
  }
  constraints.audio = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    optional: [...audioConstraints],
  };
  if (ev.selectedAudioDevice) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    constraints.audio.mandatory = { sourceId: ev.selectedAudioDevice.value };
  }

  //------------------------------Video constraints ------------------------------------------
  const mirrorStore = $mirrorStore.getState();
  const isFirstVideoRequest = !('videoPreview' in mirrorStore); // needed for set params to constraints.video at first time
  const hasAlreadyEnabledVideo = Boolean(mirrorStore.videoPreview);
  const shouldSelectedVideoDeviceChange = Boolean(ev.selectedVideoDevice);
  const isVideoPermamentDisabled = $devices.getState().videoDisabled;
  const selectedQuality = $devices.getState().selectedQuality?.value;
  const defaultVideoSettings = {
    height: { max: 1080, min: 360, ideal: selectedQuality || 720 },
    aspectRatio: { exact: 16 / 9 },
  };

  if (
    (!isFirstVideoRequest && !hasAlreadyEnabledVideo && !shouldSelectedVideoDeviceChange) ||
    isVideoPermamentDisabled
  ) {
    constraints.video = false;
  } else {
    const newVideoDevice = { deviceId: ev.selectedVideoDevice?.value };
    const currentVideoDevice = $devices.getState().selectedVideoDevice;

    constraints.video = {
      ...defaultVideoSettings,
      ...(ev.selectedVideoDevice ? newVideoDevice : currentVideoDevice),
    };
  }

  console.log(
    'mirrorMedia store',
    'requestMirrorStream.use get constraints',
    JSON.stringify(constraints)
  );
  return constraints;
};

export { requestMirrorStreamFx };
